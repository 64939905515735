import { FLexBoxProps } from '@rario/shared-components'
import { NFTCatalogDoc, NFTTokenDoc, NFTTokenListings, StylePropertyValue } from 'interfaces'
import { Hit } from 'react-instantsearch-core'
import { getCDNUrl } from 'utils/utils'

export interface NFTDetailProps {
  catalogDoc: NFTCatalogDoc
  tokenDoc?: NFTTokenDoc
  tokenListings?: NFTTokenListings[]
  isPick?: boolean
}

export interface NFTDetailFirstFoldProps {
  catalogDoc: NFTCatalogDoc
  tokenDoc?: NFTTokenDoc
  height?: StylePropertyValue
  width?: StylePropertyValue
  showBoostModalCallback?: (type: string) => void
}

export interface NFTDetailInfoProps {
  catalogDoc: NFTCatalogDoc
  tokenDoc?: NFTTokenDoc | { serial_number: number }
  isOnModal?: boolean
  titleFlexBoxProps?: FLexBoxProps
  showPoints?: boolean
  isFromPreview?: boolean
  status?: string
  isCardDetail?: boolean
  isPackDisplay?: boolean
  isFromPurchaseScreen?: boolean
  xp?: number
  compact?: boolean
  isSold?: boolean
  showBoostModalCallback?: (type: string) => void
}

export interface NFTDetailSingleImageProps {
  imageUrl: string
  height?: StylePropertyValue
  width?: StylePropertyValue
  margin?: StylePropertyValue
  onClick?: Function
  cursor?: string
  alt?: string
  scarcity?: string
  is_star_player?: boolean
  isFromPackReveal?: boolean
}

export interface NFTSalesPriceRowProps {
  catalogDoc: NFTCatalogDoc
  tokenDoc?: NFTTokenDoc
  listingPrice: string | undefined
}

export interface NFTSelectAndBuyButtonProps {
  catalogDoc: NFTCatalogDoc
  tokenDoc: NFTTokenDoc
}

export interface NFTTokenButtonProps {
  catalogDoc: NFTCatalogDoc
  tokenDoc: NFTTokenDoc
  onChangePriceCallback: Function
  tagStatus?: string
  updateTokenStatusCallback: Function
}

export interface NFTSelectAndBuyListingProps {
  catalogDoc: NFTCatalogDoc
  totalListing: number
}

export interface NftCardProps {
  hit: Hit<NFTCatalogDoc>
  activeIndexTitle?: string
  appendRefererInLink?: boolean
}

export interface PriceProps {
  priceTitle: string
  minSalePrice: number
}

export enum ModalDataTypeEnum {
  // eslint-disable-next-line no-unused-vars
  xp = 'xp',
  // eslint-disable-next-line no-unused-vars
  boost = 'boost',
  // eslint-disable-next-line no-unused-vars
  nsb = 'nsb',
}

export const cardScarcityBackground: { [key: string]: string } = {
  black: getCDNUrl('/App/images/marketplace/ogCards_black_bg.webp'),
  gold: getCDNUrl('/App/images/marketplace/ogCards_Gold_bg.webp'),
  silver: getCDNUrl('/App/images/marketplace/ogCards_silver_bg.webp'),
  bronze: getCDNUrl('/App/images/marketplace/ogCards_bronze_bg.webp'),
  blue: getCDNUrl('/App/images/marketplace/ogCards_blue_bg.webp'),
}
