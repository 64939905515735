import { customStyled, FlexBox, ThemeType } from '@rario/shared-components'

export const TagContainer = customStyled(FlexBox)<{
  theme?: ThemeType
  background?: string
  left?: string
}>`
      cursor: default;
      position: relative;
      width: auto;
      height: 25px;
      top: 0px;
      mask-image: linear-gradient(58deg, white calc(100% - 25px), transparent calc(100% - 25px));
      padding: 
      5px 35px 2px 10px;
      background: ${({
        background = `linear-gradient(180deg, #686B70 26.09%, rgba(51, 51, 51, 0.65) 94.2%)`,
      }) => background};
      box-shadow: 5.49593px 0px 9.15989px rgba(0, 0, 0, 0.45);
      backdrop-filter: blur(10px);
      border-top-left-radius: 10px;
`

export const NewTagContainer = customStyled(FlexBox)<{
  theme?: ThemeType
  left?: string
}>`
      cursor: default;
      position: relative;
      width: fit-content;
      height: 25px;
      top: 0px;
      left: -20px;
      mask-image: linear-gradient(64deg, white 80%, transparent 80%), radial-gradient(farthest-side at 10% 50%, white 80%, transparent 80%);
      padding: 5px 20px 2px 10px;
      background: ${({ theme }) => theme.gradients.tagContainerBackground};
      box-shadow: 5.49593px 0px 9.15989px rgba(0, 0, 0, 0.45);
      backdrop-filter: blur(10px);
      border-top-right-radius: 10px;
`

export const PackTagContainer = customStyled(FlexBox)<{
  theme?: ThemeType
  background?: string
  left?: string
}>`
      cursor: default;
      position: relative;
      width: auto;
      height: 23px;
      top: 0px;
      mask-image: linear-gradient(58deg, white calc(100% - 25px), transparent calc(100% - 25px));
      padding: 
      5px 30px 2px 10px;
      background: ${({
        background = `linear-gradient(180deg, #686B70 26.09%, rgba(51, 51, 51, 0.65) 94.2%)`,
      }) => background};
      box-shadow: 5.49593px 0px 9.15989px rgba(0, 0, 0, 0.45);
      backdrop-filter: blur(10px);
      border-top-left-radius: 10px;
`
