const AllRounderIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '14', height = '13', fill = 'white' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3938 5.37782C10.0441 5.84043 10.0048 6.54987 10.1873 7.20037L3.13195 14.3302C2.86331 14.6016 2.51301 14.5057 2.17347 14.3338C1.21992 13.8512 0.783838 13.4226 0.189766 12.358C-0.00727792 12.0048 -0.112249 11.6379 0.186243 11.3362L1.93609 9.56789C3.05317 10.0759 4.41564 9.86552 5.33143 8.93996C6.24716 8.01453 6.44634 6.64683 5.93102 5.5308L7.18312 4.26546C7.76101 4.34101 8.54556 4.35603 9.05653 4.1251L10.3938 5.37782ZM10.8867 4.86415L11.4594 4.2855L10.1606 3.06875L9.59905 3.65784L10.8867 4.86415ZM11.9604 3.77937L10.6518 2.55349L11.1907 1.98817L12.51 3.22407L11.9604 3.77937ZM13.0109 2.71793L13.0977 2.6303C13.3076 2.71965 13.5771 2.68861 13.7561 2.50768C13.9949 2.26646 13.9937 1.90594 13.7533 1.66648L12.7616 0.678713C12.5212 0.439256 12.1619 0.44058 11.9231 0.681809C11.744 0.862737 11.715 1.13323 11.8055 1.3432L11.682 1.4729L13.0109 2.71793Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.22827 8.34156C1.27779 8.3034 1.338 8.27778 1.40462 8.27016C1.54144 8.25443 1.71445 8.2031 1.91642 8.10921C2.09469 8.02635 2.30636 8.10365 2.38922 8.28191C2.47208 8.46016 2.39475 8.67186 2.21649 8.75473C2.09026 8.81338 1.96361 8.86321 1.83851 8.90151C2.80375 9.5276 4.10738 9.41277 4.95054 8.5606C5.79882 7.70337 5.89926 6.38457 5.2544 5.4201C5.20074 5.55728 5.14104 5.69234 5.07616 5.82484C4.98971 6.00139 4.77651 6.07443 4.59997 5.98798C4.42342 5.90152 4.35038 5.68833 4.43683 5.51178C4.52691 5.32782 4.6043 5.14083 4.66673 4.95245C4.67982 4.91294 4.69917 4.87727 4.7233 4.84616C3.74573 4.07561 2.32459 4.14656 1.42622 5.05448C0.534118 5.95605 0.469125 7.36801 1.22827 8.34156ZM4.33448 6.98966C4.45659 6.83561 4.43069 6.61173 4.27664 6.48964C4.12258 6.36749 3.89871 6.3934 3.7766 6.54745C3.51158 6.88181 3.21784 7.18385 2.91985 7.43906C2.77055 7.56698 2.75318 7.79164 2.88106 7.94092C3.00893 8.09027 3.23362 8.10764 3.38292 7.97972C3.71516 7.69519 4.04073 7.36025 4.33448 6.98966Z"
      fill={fill}
    />
  </svg>
)

export default AllRounderIcon
