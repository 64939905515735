const BatIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '12', height = '12', fill = 'white' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.95755 4.21487C8.67102 4.59396 8.63882 5.17531 8.78833 5.70836L3.00688 11.5508C2.78675 11.7733 2.4997 11.6946 2.22146 11.5538C1.44008 11.1583 1.08274 10.8071 0.595927 9.93472C0.434466 9.64533 0.348448 9.34462 0.593046 9.09747L6.3266 3.30336C6.80015 3.36526 7.44304 3.37758 7.86176 3.18834L8.95755 4.21487ZM8.30632 2.80545L8.76645 2.32273L9.8308 3.31978L9.36151 3.79395L8.30632 2.80545ZM10.6917 2.45L10.2413 2.90503L9.16901 1.90049L9.61059 1.43724L10.6917 2.45ZM10.0132 1.01501L11.1022 2.03524L11.1733 1.96343C11.3453 2.03665 11.5661 2.01122 11.7128 1.86296C11.9085 1.66529 11.9075 1.36986 11.7105 1.17364L10.8979 0.364219C10.7009 0.167997 10.4064 0.169082 10.2107 0.366756C10.064 0.515016 10.0402 0.736671 10.1144 0.908731L10.0132 1.01501Z"
      fill={fill}
    />
  </svg>
)

export default BatIcon
