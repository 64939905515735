const SparkleIcon: React.FunctionComponent<{
  width?: string
  height?: string
}> = ({ width = '12', height = '13' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_479_49986)">
      <path
        d="M5.36756 2.54834C5.36756 5.24764 3.18343 7.43151 0.484375 7.43151C3.18368 7.43151 5.36756 9.61564 5.36756 12.3147C5.36756 9.61539 7.55169 7.43151 10.2508 7.43151C7.55144 7.43151 5.36756 5.24739 5.36756 2.54834Z"
        fill="url(#paint0_linear_479_49986)"
      />
      <path
        d="M9.17888 0.5625C9.17888 2.04764 7.97719 3.24919 6.49219 3.24919C7.97731 3.24919 9.17888 4.45088 9.17888 5.93587C9.17888 4.45074 10.3806 3.24919 11.8656 3.24919C10.3804 3.24919 9.17888 2.0475 9.17888 0.5625Z"
        fill="url(#paint1_linear_479_49986)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_479_49986"
        x1="2.92569"
        y1="3.70703"
        x2="6.93788"
        y2="11.5985"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00124008" stopColor="#FFEF9A" />
        <stop offset="1" stopColor="#FFC03A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_479_49986"
        x1="7.83538"
        y1="1.2"
        x2="10.0429"
        y2="5.54186"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00124008" stopColor="#FFEF9A" />
        <stop offset="1" stopColor="#FFC03A" />
      </linearGradient>
      <clipPath id="clip0_479_49986">
        <rect width="10.7143" height="12.5" fill="white" transform="translate(0.710938 0.25)" />
      </clipPath>
    </defs>
  </svg>
)

export default SparkleIcon
