import { FlexBox, TextField, theme } from '@rario/shared-components'
import AllRounderIcon from 'assets/svg/AllRounderIcon'
import BallIcon from 'assets/svg/BallIcon'
import BatIcon from 'assets/svg/BatIcon'
import SparkleIcon from 'assets/svg/SparkleIcon'
import WicketKeeperIcon from 'assets/svg/WicketKeeperIcon'
import { NFTTagProps } from 'interfaces'
import { NewTagContainer, TagContainer } from './NFTCardRoleTag.styles'

export const cardRoleTag: { [key: string]: { icon: JSX.Element } } = {
  Batter: { icon: <BatIcon width="15px" height="15px" /> },
  Bowler: { icon: <BallIcon width="15px" height="15px" /> },
  WK: { icon: <WicketKeeperIcon width="19px" height="19px" /> },
  Allrounder: { icon: <AllRounderIcon width="15px" height="15px" /> },
}

const NFTCardRoleTag: React.FunctionComponent<{
  tagProps: NFTTagProps[]
}> = ({ tagProps }) => {
  return (
    <FlexBox flexDirection={'row'} position="relative" left={'15px'}>
      {tagProps.map((item, index) => {
        return (
          <FlexBox key={`nftCard-tag-${index}`}>
            {item.role && (
              <TagContainer zIndex={tagProps.length - index} key={index}>
                {cardRoleTag[item.role].icon}
                {(item.batting_position || item.bowling_position) && (
                  <FlexBox position={'relative'} left={'5px'} top={'5px'}>
                    <TextField
                      fontWeight={400}
                      fontSize={'12px'}
                      lineHeight={'10px'}
                      color={theme.colors.white}
                      textAlign={'left'}
                      content={`#${item.batting_position || item.bowling_position}`}
                    />
                  </FlexBox>
                )}
              </TagContainer>
            )}
            {item?.tagText && item?.tagText === 'NEW' && (
              <NewTagContainer ml="-12px">
                <FlexBox position={'relative'} top={'2px'} pr="3px" pl="8px">
                  <TextField
                    fontWeight={500}
                    fontSize={'12px'}
                    lineHeight={'10px'}
                    color={theme.colors.white}
                    textAlign={'left'}
                    content={'NEW'}
                  />
                </FlexBox>
                <SparkleIcon width="15px" height="15px" />
              </NewTagContainer>
            )}
          </FlexBox>
        )
      })}
    </FlexBox>
  )
}

export default NFTCardRoleTag
