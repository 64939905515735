import { useState, useEffect } from 'react'

const useCloudinaryUrl = (
  imageUrl: string,
  width: string,
  height: string,
  quality: string = 'auto:good',
  scale: boolean = false,
  fill: boolean = true,
  format?: string
) => {
  const [url, setUrl] = useState('')

  useEffect(() => {
    const [cloudName, publicId] = imageUrl.split('/upload/')
    const url = `${cloudName}/upload/q_${quality}${
      format ? `,${format}` : ',f_auto'
    }${`,w_${width},h_${height}`}${fill ? ',c_fill' : ''}${scale ? ',c_scale' : ''}/${publicId}`
    setUrl(url)
  }, [imageUrl, width, height])

  return url
}

export default useCloudinaryUrl
